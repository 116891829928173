.fullscreen_loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
/* 
.cube-loader {
  position: relative;
  width: 60px;
  height: 60px;
  transform-style: preserve-3d;
  animation: spin-cube 2s infinite linear;
}

.side {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: #36c5f0;
  opacity: 0.9;
}

.front {
  transform: translateZ(30px);
}
.back {
  transform: rotateY(180deg) translateZ(30px);
}
.right {
  transform: rotateY(90deg) translateZ(30px);
}
.left {
  transform: rotateY(-90deg) translateZ(30px);
}
.top {
  transform: rotateX(90deg) translateZ(30px);
}
.bottom {
  transform: rotateX(-90deg) translateZ(30px);
}

@keyframes spin-cube {
  0% {
    transform: rotateX(0) rotateY(0);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg);
  }
} */


.motion_blur {
    width: 8rem;
    height: 8rem;
}


.car__body {
  -webkit-animation: shake 0.2s ease-in-out infinite alternate;
          animation: shake 0.2s ease-in-out infinite alternate;
}
.car__line {
  transform-origin: center right;
  stroke-dasharray: 22;
  -webkit-animation: line 0.8s ease-in-out infinite;
          animation: line 0.8s ease-in-out infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.car__line--top {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.car__line--middle {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.car__line--bottom {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

@-webkit-keyframes shake {
  0% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(3%);
  }
}

@keyframes shake {
  0% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(3%);
  }
}
@-webkit-keyframes line {
  0% {
    stroke-dashoffset: 22;
  }
  25% {
    stroke-dashoffset: 22;
  }
  50% {
    stroke-dashoffset: 0;
  }
  51% {
    stroke-dashoffset: 0;
  }
  80% {
    stroke-dashoffset: -22;
  }
  100% {
    stroke-dashoffset: -22;
  }
}
@keyframes line {
  0% {
    stroke-dashoffset: 22;
  }
  25% {
    stroke-dashoffset: 22;
  }
  50% {
    stroke-dashoffset: 0;
  }
  51% {
    stroke-dashoffset: 0;
  }
  80% {
    stroke-dashoffset: -22;
  }
  100% {
    stroke-dashoffset: -22;
  }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.car-wheel--front {
    animation: rotate 1s linear infinite;
    transform-origin: 85.493px 31.25px;
}

.car-wheel--rear {
    animation: rotate 1s linear infinite;
    transform-origin: 48.511px 31.25px;
}
.sp_price {
  font-size: 1.4rem;
  color: #fff;
  margin-bottom: 2rem;
}
/* Specification */
.sp_specification {
    padding: 20rem 0 4rem;
    position: relative;
    background-image: linear-gradient(to bottom, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 87%) 100%);
}
.spc_head p,
.config_body p {
    font-size: 1.1rem;
}
.spc_head p,
.model_desc .title {
  color: #fff;
}
.col-five-one {
  flex: 0 0 auto;
  width: 20%;
}
.spc_body {
  text-align: center;
}
/* Specification End*/
.build_area {
  background-color: #e6e6e6;
  position: relative;
}
.threesixty_view {
  width: 100%;
  margin: auto;
  position: relative;
}
.threesixty_view canvas,
.pano_view .pnlm-container {
  height: 540px !important;
  object-fit: cover;
  display: block;
  object-position: center;
}
.car_colors,
.car_options {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
}
.car_colors {
  right: 4rem;
  gap: 1rem;
}
.car_options {
  left: 4rem;
  gap: 1.5rem;
}
.color_item {
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  flex: 0 0 2rem;
  border: 2px solid #fff;
  box-shadow: 0 1px 2px rgb(20 25 36 / 68%);
  cursor: pointer;
}
.co_lagend {
  color: #5c5c5c;
  font-size: 1rem;
}
.show_360_view {
  display: block !important;
}
.hide_360_view {
  display: none !important;
}

.co_item {
  text-align: center;
  padding: 0.2rem;
  width: 95px;
  height: 67px;
  transition: opacity 0.3s ease;
  opacity: 0.6;
  cursor: pointer;
}
.co_item:hover,
.co_item.active {
  opacity: 1;
}

.img_cont > img {
  width: 100%;
}

/* Configuration */
.configuration {
  padding: 4rem 0;
}
/* Configuration End */
.sp_gallery {
  padding: 4rem 0;
  background-color: #e9e9e9;
}
.thumbsSwiper {
  margin-top: 0.4rem;
}
.gallerySwiper .swiper-slide > img {
  width: 100%;
  height: 450px;
  transition: transform 0.3s ease;
  filter: brightness(40%);
  object-fit: cover;
  object-position: center;
}

.gallerySwiper .swiper-slide-active.swiper-slide > img {
  transform: scale(1);
  filter: brightness(100%);
}
.gallerySwiper .swiper-slide.swiper-slide-prev img,
.gallerySwiper .swiper-slide.swiper-slide-next img {
  transform: scale(0.95);
}
.gallerySwiper .swiper-button-prev,
.gallerySwiper .swiper-button-next {
  color: #fff;
}
.gallerySwiper .swiper-button-prev {
  left: 4rem;
}
.gallerySwiper .swiper-button-next {
  right: 4rem;
}
.thumbsSwiper .swiper-slide {
  padding: 0.2rem;
}
.thumbsSwiper .swiper-slide img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 0.4rem;
  transition: box-shadow, opacity, transform 0.3s ease;
  opacity: 0.6;
  cursor: pointer;
}
.thumbsSwiper .swiper-slide:not(.swiper-slide-thumb-active) img:hover {
  transform: scale(0.95);
}
.thumbsSwiper .swiper-slide-thumb-active img {
  opacity: 1;
  box-shadow: 0 0 0 2px #12120e;
  transform: scale(0.98);
  padding: 0.15rem;
}

.threesixty_view {
  display: none;
}
.threesixty_view.active {
  display: block;
}
.pano_view {
  display: none;
}
.pano_view.active {
  display: block;
}

#v360-menu-btns {
  display: none !important;
}

@media screen and (min-width: 992px) {
  .config_body {
    padding-left: 2rem;
  }
}

@media screen and (max-width: 991px) {
  .thumbsSwiper .swiper-slide img {
    width: 100%;
    height: 60px;
  }
  .spc_head p {
    text-shadow: 1px 1px #000;
    color: #fff;
    font-size: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .threesixty_view canvas, .pano_view .pnlm-container {
    height: 360px !important;
  }
  .co_lagend {
    font-size: .9rem;
  }
  .co_icon svg {
    width: 2.2rem !important;
  }
  .car_options {
    gap: .5rem;
  }
  .sp_specification {
    padding: 6rem 0 4rem;
  }
  .spc_head p, .config_body p {
    font-size: 1rem;
  }
  .col-five-one {
    flex: 0 0 auto;
    width: 33.33%;
    padding: 0.6rem;
  }
  .model_info_item + .model_info_item {
    border: 0;
  }
  .config_img {
    margin-bottom: 2rem;
  }
  .gallerySwiper .swiper-slide > img {
    height: 280px;
  }
  .gallerySwiper .swiper-button-prev {
    left: 2rem;
  }
  .gallerySwiper .swiper-button-next {
    right: 2rem;
  }
  .gallerySwiper [class*="swiper-button-"]:after {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 575px) {
  .sp_price {
    font-size: 1rem;
  }
  .sp_specification {
    background-image: linear-gradient(
      to bottom,
      rgb(0 0 0 / 50%) 0%,
      rgb(0 0 0 / 80%) 100%
    );
    text-align: center;
  }
  .spc_head .title {
    color: #fff;
  }
  .co_icon svg {
    max-width: 40px;
    max-height: 25px;
  }
  .car_options {
    left: 1.4rem;
    gap: 0.5rem;
  }
  .co_lagend {
    font-size: 0.8rem;
  }
  .color_item {
    width: 1.5rem;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }
  .car_colors {
    right: 3rem;
    gap: 0.5rem;
  }
}
@media screen and (max-width: 480px) {
  .threesixty_view canvas, 
  .pano_view .pnlm-container {
      height: 240px !important;
  }
  .car_options {
    left: 0;
    right: 0;
    bottom: -80px;
    flex-direction: row;
    top: auto;
  }
  .build_area {
      margin-bottom: 3rem;
  }
  .gallerySwiper .swiper-slide > img {
    height: 200px;
  }
  .thumbsSwiper .swiper-slide img {
    height: 40px;
  }
}


/*Footer start
-------------------------*/
.footer {
    background-color: #E9E9E9;
    position: relative;
}
.backToTop {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #e9e9e9;
    border-radius: 2rem;
    padding: 0.4rem 1rem;
    line-height: 1;
    font-size: .8rem;
    text-transform: uppercase;
    cursor: pointer;
}
.backToTop:hover {
    border-color: #484848;
    color: #030b18;
}
.social_links li a {
    width: 2.4rem;
    height: 2.4rem;
    display: block;
    font-size: 18px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #787c81;
    transition: background-color , color .3s ease-in-out;
}
.social_links li a:hover {
    background-color: #d9d9d9;
}
.footer_title {
    font-size: 18px;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
}
.menu_links li a {
    color: #2F2F2F;
    font-size: 1rem;
    text-decoration: none;
}
.menu_links li a:hover {
    text-decoration: underline;
}
.copyright {
    border-top: 1px solid #d5d5d5;
    padding: 1.2rem 0;
    margin-top: 2rem;
    font-size: .9rem;
}
.privacy_menu .list-inline-item a {
    color: #2C2C2C;
    text-decoration: none;
    font-weight: 500;
}
.privacy_menu .list-inline-item a:hover {
    text-decoration: underline;
}
.social_links {
    text-align: right;
}
.privacy_menu {
    text-align: center;
}
/*Footer End
--------------------------*/

@media screen and (max-width: 991px) { 
    .footer_title {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 767px) { 
    .footer_item {
      margin: 15px 0;
    }
    .privacy_menu,
    .copyright_text,
    .social_links {
      text-align: center;
    }
  }
  

@font-face {
  font-family: "TT Hoves Pro Trl Exp";
  src: url("./assets/fonts/TTHovesProTrlExp-Bd.eot");
  src: url("./assets/fonts/TTHovesProTrlExp-Bd.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/TTHovesProTrlExp-Bd.woff2") format("woff2"),
    url("./assets/fonts/TTHovesProTrlExp-Bd.woff") format("woff"),
    url("./assets/fonts/TTHovesProTrlExp-Bd.ttf") format("truetype"),
    url("./assets/fonts/TTHovesProTrlExp-Bd.svg#TTHovesProTrlExp-Bd")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "TT Hoves Pro Trl Exp";
  src: url("./assets/fonts/TTHovesProTrlExp-BdIt.eot");
  src: url("./assets/fonts/TTHovesProTrlExp-BdIt.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/TTHovesProTrlExp-BdIt.woff2") format("woff2"),
    url("./assets/fonts/TTHovesProTrlExp-BdIt.woff") format("woff"),
    url("./assets/fonts/TTHovesProTrlExp-BdIt.ttf") format("truetype"),
    url("./assets/fonts/TTHovesProTrlExp-BdIt.svg#TTHovesProTrlExp-BdIt")
      format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "TT Hoves Pro Trl Exp";
  src: url("./assets/fonts/TTHovesProTrlExp-Md.eot");
  src: url("./assets/fonts/TTHovesProTrlExp-Md.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/TTHovesProTrlExp-Md.woff2") format("woff2"),
    url("./assets/fonts/TTHovesProTrlExp-Md.woff") format("woff"),
    url("./assets/fonts/TTHovesProTrlExp-Md.ttf") format("truetype"),
    url("./assets/fonts/TTHovesProTrlExp-Md.svg#TTHovesProTrlExp-Md")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "TT Hoves Pro Trl Exp";
  src: url("./assets/fonts/TTHovesProTrlExp-MdIt.eot");
  src: url("./assets/fonts/TTHovesProTrlExp-MdIt.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/TTHovesProTrlExp-MdIt.woff2") format("woff2"),
    url("./assets/fonts/TTHovesProTrlExp-MdIt.woff") format("woff"),
    url("./assets/fonts/TTHovesProTrlExp-MdIt.ttf") format("truetype"),
    url("./assets/fonts/TTHovesProTrlExp-MdIt.svg#TTHovesProTrlExp-MdIt")
      format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "TT Hoves Pro Trl Exp";
  src: url("./assets/fonts/TTHovesProTrlExp-Rg.eot");
  src: url("./assets/fonts/TTHovesProTrlExp-Rg.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/TTHovesProTrlExp-Rg.woff2") format("woff2"),
    url("./assets/fonts/TTHovesProTrlExp-Rg.woff") format("woff"),
    url("./assets/fonts/TTHovesProTrlExp-Rg.ttf") format("truetype"),
    url("./assets/fonts/TTHovesProTrlExp-Rg.svg#TTHovesProTrlExp-Rg")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "TT Hoves Pro Trl Exp";
  src: url("./assets/fonts/TTHovesProTrlExp-It.eot");
  src: url("./assets/fonts/TTHovesProTrlExp-It.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/TTHovesProTrlExp-It.woff2") format("woff2"),
    url("./assets/fonts/TTHovesProTrlExp-It.woff") format("woff"),
    url("./assets/fonts/TTHovesProTrlExp-It.ttf") format("truetype"),
    url("./assets/fonts/TTHovesProTrlExp-It.svg#TTHovesProTrlExp-It")
      format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

:root {
  --primary-color: #cdde04;
  --dark-color: #1c1c1c;
}
body {
  color: #2f2f2f;
  font-family: "TT Hoves Pro Trl Exp";
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size-adjust: 100%;
  font-weight: normal;
  background-color: #fff;
  line-height: 1.8;
}
a {
  color: #fff;
}
a:hover {
  color: var(--primary-color);
}
.stack {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.btn.btn_primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  font-size: 1rem;
  font-weight: 500;
  color: #2a251d;
  padding: 0.8rem 1.2rem;
  position: relative;
  overflow: hidden;
  line-height: 1.2;
  border-radius: 2rem;
}
.btn.btn_primary.btn-sm {
  padding: 0.5rem 1.2rem;
}
.btn.btn_primary:not(.btn-block):after {
  content: "";
  position: absolute;
  width: 80px;
  height: 180px;
  background-color: #fff;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 49%,
    rgba(255, 255, 255, 0) 100%
  );
  right: -100px;
  transform: rotate(45deg);
  top: -60px;
  transition: all 0.8s ease;
}
.btn.btn_primary:not(.btn-block):hover:after {
  right: 300px;
}
.btn.btn_primary:hover,
.btn.btn_primary:focus,
.btn.btn_primary:not(:disabled):not(.disabled).active,
.btn.btn_primary:not(:disabled):not(.disabled):active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #2a251d;
}
.btn.btn_outline {
  padding: 0.8rem 1.5rem;
  position: relative;
  overflow: hidden;
  line-height: 1.2;
  border: 1px solid var(--primary-color);
  border-radius: 2rem;
  font-size: 1rem;
  color: var(--primary-color);
  font-weight: 500;
}
.btn.btn_outline:hover,
.btn.btn_outline:focus,
.btn.btn_outline:not(:disabled):not(.disabled).active,
.btn.btn_outline:not(:disabled):not(.disabled):active {
  background-color: var(--primary-color);
  color: var(--dark-color);
}
.btn.btn_outline.outline_dark {
  border-color: var(--dark-color);
  color: var(--dark-color);
}
.btn.btn_outline.outline_dark:hover {
  background-color: var(--dark-color);
  color: #fff;
}
.btn.btn_outline.outline_light {
  border-color: #fff;
  color: #fff;
}
.btn.btn_outline.outline_light:hover {
  background-color: #fff;
  color: var(--dark-color);
}

.heading {
  margin-bottom: 2rem;
}
.title {
  font-weight: normal;
  color: #1c1c1c;
}
.title_lg {
  font-size: 2.8rem;
}
.sub_title {
  font-size: 1rem;
  color: #2f2f2f;
}
.model_item .btn {
  padding: 0.8rem 1rem;
}

.ml-auto {
  margin-left: auto;
}
.pagination_light {
  display: flex;
  justify-content: center;
  height: 1rem;
  align-items: center;
  z-index: 1;
}
.pagination_light .swiper-pagination-bullet {
  background-color: #707173;
  width: 5px;
  height: 5px;
  transition: box-shadow 0.3s ease;
  opacity: 1;
}
.scroll_bottom {
  position: absolute;
  bottom: 4rem;
  left: 50%;
  width: 1.6rem;
  height: 3rem;
  border: 2px solid #fff;
  z-index: 1;
  border-radius: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  z-index: 10;
  margin-left: -0.8rem;
}
.banner .scroll_bottom {
  left: auto;
  right: 5rem;
}
.scroll_bottom svg {
  animation: wheel 2s infinite;
  -webkit-animation: wheel 2s infinite;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translatex(-50%);
}
@keyframes wheel {
  to {
    opacity: 0;
    top: 40px;
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 40px;
  }
}
.model_info {
  color: #fff;
}
.model_info_item {
  padding: 0rem 0.5rem;
}
.model_info_title {
  font-size: 1.2rem;
  font-weight: 700;
}
.model_info_legend {
  font-size: 1rem;
}
.model_info_item + .model_info_item {
  border-left: 2px solid #cad3ed;
}
.page_banner {
  position: relative;
}
.page_banner > img {
  height: 700px;
  width: 100%;
  object-fit: cover;
}
.page_banner.pb_sm > img {
    height: 440px;
    width: 100%;
    object-fit: cover;
}
.pb_caption {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  padding-top: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pb_title {
  font-size: 3rem;
  margin-bottom: 0.5rem;
  color: #fff;
  font-weight: 700;
  position: relative;
  display: inline-block;
}
.pb_text {
  color: #fff;
  font-size: 1.1rem;
}

.required {
  color: red !important;
}
.invalid_input {
  color: red !important;
}

/*Media query*/
@media screen and (max-width: 991px) {
  .heading {
    margin-bottom: 1.5rem;
  }
  .page_banner > img {
    height: 540px;
  }
  .title_lg {
    font-size: 2.2rem;
  }
  .footer_title {
    font-size: 16px;
  }
  .swiper .you-slide-image {
    height: 362px;
  }
  .btn.btn_outline,
  .btn.btn_primary {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 767px) {
  .title_lg {
    font-size: 1.8rem;
  }
  .sub_title {
    font-size: 0.9rem;
  }
  .btn.btn_primary,
  .btn.btn_outline_primary {
    font-size: 13px;
    padding: 0.6rem 0.9rem;
  }
  .stack {
    flex-direction: column;
    gap: 0.4rem;
  }
}

@media (max-width: 575px) {
  .heading {
    margin-bottom: 0.9rem;
  }
  .title_lg {
    font-size: 1.4rem;
  }
  .page_banner > img {
    height: 420px;
  }
  .pb_title {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .swiper .you-slide-image {
    height: 260px;
  }
}

@media (min-width: 620px) {
  .container {
    max-width: 600px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
  .va_image {
    text-align: right;
  }
}

@media (min-width: 820px) {
  .container {
    max-width: 800px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1180px;
  }
}

.pod_area {
    padding: 10rem 0 4rem;
}
.pod_form_legend {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: .6rem;
    line-height: normal;
}
.send_otp {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: .8rem;
    cursor: pointer;
}

.send_otp:hover {
    text-decoration: underline;
}
.pof_scrol {
    max-height: 400px;
    overflow-y: auto;
    margin: 0 -.75rem;
    padding: 0 .75rem;
}
.react-tel-input .form-control {
    width: 100%;
}
.pof_header {
    margin-bottom: 2rem;
}
.stepItem {
    flex: 1 0;
    text-align: center;
    position: relative;
}
.step_count {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    text-align: center;
    line-height: 3rem;
    border: 2px solid #c0c4cb;
    color: #c0c4cb;
    background-color: #fff;
    box-shadow: 0 0 0 0.8rem #fff;
}
.step_label {
    font-size: .9rem;
    font-weight: 600;
    color: #c0c4cb;
}

.stepItem:not(:last-child):after, 
.stepItem:not(:first-child):before {
    content: "";
    position: absolute;
    height: .15rem;
    background-color: #c0c4cb;
    top: 1.5rem;
    z-index: -1;
    transition: all .3s ease-in-out;
}
.stepItem:not(:last-child):after {
    left: 50%;
    right: 0;
}
.stepItem:not(:first-child):before {
    left: 0;
    right: 50%;
}

.stepItem:not(:first-child).active:before,
.stepItem:not(:first-child).done:before,
.stepItem:not(:last-child).done:after {
    background-color: #000;
}
.stepItem.done .step_count,
.stepItem.active .step_count,
.stepItem.done .step_label,
.stepItem.active .step_label  {
    color: #000;
    border-color: #000;
}

.checkboax_area label {
    font-size: .8rem;
}
.react-tel-input .flag-dropdown.open {
    z-index: 99;
}
.pod_title h2 {
    font-size: 2rem;
    font-weight: 600;
}
@media screen and (max-width: 767px) {
    .pof_scrol {
        max-height: none;
        overflow-y: visible;
    }
    .pre_order_car {
        margin-top: 2rem;
    }
    .mx-0 > .ps-0 {
        padding-right: 0;
    }
    .mx-0 > .pe-0 {
        padding-left: 0;
    }
}
/*Banner*/
.banner_caption {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  padding-top: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner_caption > div {
  transition: all 0.3s ease-in-out;
}
.banner .swiper-slide > img {
  object-fit: cover;
  width: 100%;
  height: 100vh;
}
.banner .swiper-slide-active.swiper-slide > img {
  animation: zoom 40s;
  -webkit-animation: zoom 40s;
}
@keyframes zoom {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.4);
  }
}
@-webkit-keyframes zoom {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.4);
  }
}

.caption_content {
  width: 500px;
  max-width: 100%;
}
.caption_subtitle {
  color: #bdbdbd;
  font-size: 0.9rem;
}
.caption_title {
  font-size: 3rem;
  margin-bottom: 2rem;
  color: #fff;
  font-weight: 700;
  position: relative;
  display: inline-block;
}

.caption_body {
  color: #d3d3d3;
  font-size: 1.15rem;
  margin-bottom: 2rem;
}
.pagination_banner {
  position: absolute;
  bottom: 2rem !important;
  left: 0;
  right: 0;
}
.pagination_light.has_progress
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 60px;
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #707173;
}
.pagination_light.has_progress .swiper-pagination-bullet {
  border-radius: 0.2rem;
  transition: width 0.3s ease;
  position: relative;
}
.pagination_light.has_progress
  .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: #fff;
  border-radius: 0.2rem;
  animation: fullWidth 6s;
  -webkit-animation: fullWidth 6s;
}

@keyframes fullWidth {
  from {
    width: 0;
  }
  to {
    width: 60px;
  }
}

@-webkit-keyframes fullWidth {
  from {
    width: 0;
  }
  to {
    width: 60px;
  }
}
/*Banner End*/

/* About */
.about_us {
  padding: 6rem 0;
  background: #f1f1f1;
  /* background: #fff url("./../../assets/images/about_us.webp") no-repeat; */
  background-size: cover;
  position: relative;
}

/* About End */

/*Models*/
.models_area {
  background-color: #fff;
  padding: 4rem 0;
  overflow-x: hidden;
}

.nav_tab_models {
  display: flex;
  justify-content: center;
  border: none;
}
.nav_tab_models > .nav-item > .nav-link:not(.active):hover,
.nav_tab_models > .nav-item > .nav-link.active {
  color: #1c1c1c;
}
.nav_tab_models > .nav-item > .nav-link {
  position: relative;
  font-size: 1.06rem;
  font-weight: 600;
  color: #6e6e6e;
  border-color: #dbdbdb;
  border-width: 0 0 1px 0;
  background-color: transparent;
}
.nav_tab_models > .nav-item > .nav-link:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  background-color: var(--primary-color);
  height: 3px;
  transform: scaleX(0);
  transition: transform 0.3s cubic-bezier(0.63, 0.01, 0.28, 0.96);
}
.nav_tab_models > .nav-item > .nav-link.active:after {
  transform: scaleX(1);
}
.model_wrap {
  padding: 24rem 2rem 2rem;
  border-radius: 0.6rem;
  position: relative;
  overflow: hidden;
}
.model_wrap:after {
  content: "";
  position: absolute;
  inset: 0;
  background-image: linear-gradient( to bottom,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 1) 100%);
  opacity: .5;
}
.model_row {
  z-index: 1;
  position: relative;
}
.model_name {
  font-size: 3.2rem;
  line-height: 1;
  color: #fff;
  margin-bottom: 1.8rem;
}

/*Models End*/

/* Automotive Elegance */
.automotive_elegance {
  padding: 8rem 0;
}

.virtual_assistant {
  padding: 2rem 0;
  background-color: #f9f9f9;
}
.ae_text {
  text-align: right;
}
.automotive_elegance .title {
  color: #fff;
}
.video_bg_cont {
  position: relative;
  overflow: hidden;
}
.video_bg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}
.video_bg > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
/* Automotive Elegance End*/

/* Social */
.overview {
  background-color: #f1f1f1;
  padding: 4rem 0;
}
.you-slide-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.you-slide-image {
  position: relative;
}
.sc_item_text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
}
.sc_item_text h5 {
  margin-bottom: 0.6rem;
  font-size: 1.8rem;
  color: #fff;
  font-weight: normal;
}
.sc_item_text p {
  margin: 0;
  color: #fff;
  line-height: 1.4;
  font-size: 0.9rem;
}
.swiper .you-slide-image {
  height: 482px;
  overflow: hidden;
  border-radius: 0.6rem;
}
.you-slide-image:after {
  content: "";
  position: absolute;
  inset: 0 0 0 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  opacity: 0.5;
}
.swiper-scrollbar {
  position: static !important;
  margin-top: 2rem;
}
.overview-nav-button {
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
}
.overview-button-prev,
.overview-button-next {
  position: relative !important;
  left: initial !important;
  right: initial !important;
  opacity: 0.5;
  margin-top: 0 !important;
}
.overview-button-prev:hover,
.overview-button-next:hover {
  opacity: 1;
}
.overview-button-prev:after,
.overview-button-next:after {
  font-size: 1.2rem !important;
  color: var(--dark-color);
}
.overview-button-prev:before,
.overview-button-next:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 1rem;
  background-color: var(--dark-color);
  top: 1.38rem;
}
.overview-button-prev:before {
  left: 0.58rem;
}
.overview-button-next:before {
  right: 0.6rem;
}
/* Social End */

/* Driving Comfort */
.driving_comfort {
  background: #16181c url("./../../assets/images/driving_comfort.jpg") no-repeat;
  background-size: cover;
  padding: 4rem 0 10rem;
}
.driving_comfort > div {
  background-position: initial !important;
}
/* Driving Comfort End */

/* News */
.news {
  background-color: #fff;
  padding: 4rem 0;
}
.news_card > img {
  height: 380px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 0.6rem;
}
.news_title {
  font-size: 1.4rem;
}
.news_card {
  padding: 1rem 0;
}
/* News End*/

@media screen and (max-width: 1199px) {
  .model_name {
    font-size: 3.2rem;
  }
  .model_info_title {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1024px) {
  .caption_title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .caption_body {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  .swiper .you-slide-image {
    height: 342px;
  }
}
@media screen and (max-width: 991px) {
  .model_name {
    font-size: 2.2rem;
  }
  .model_info_title {
    font-size: 0.9rem;
  }
  .news_card > img {
    height: 280px;
  }
  .model_wrap {
    padding: 15rem 2rem 2rem;
  }
  .automotive_elegance,
  .driving_comfort,
  .about_us {
    padding: 4rem 0;
  }
}

@media screen and (max-width: 768px) {
  .banner .swiper-slide > img {
    height: 450px;
  }
}

@media screen and (max-width: 767px) {
  .about_img {
    margin-top: 2rem;
  }
  .banner_caption {
    background-color: rgb(0 0 0 / 48%);
  }
  .models_area,
  .news {
    padding: 2rem 0;
  }
  .col-md-6.va_text,
  .news_card,
  .models_area .heading {
    text-align: center;
  }
  .va_image img {
    max-width: 60%;
    margin: auto;
    display: block;
  }
  .news_card {
    margin: 15px 0;
  }
  .fm_icon {
    flex: 0 0 1.6rem;
    max-width: 1.6rem;
  }
  .about_us .container {
    z-index: 1;
  }
  .model_wrap {
    padding: 8rem 2rem 2rem;
  }
  .model_name {
    font-size: 1.6rem;
  }
  .model_desc {
    margin-top: 1rem;
  }
}

@media (max-width: 575px) {
  .nav_tab_models > .nav-item > .nav-link {
    font-size: 0.8rem;
    padding: 0.5rem;
  }
  .model_desc > img {
    height: 280px;
  }
  .banner .swiper-slide > img {
    height: 380px;
  }
  .caption_title {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }
  .scroll_bottom {
    bottom: 1rem;
    right: 1rem;
  }
  .caption_content,
  .ae_text,
  .dc_text {
    text-align: center;
  }
  .caption_body {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    line-height: 1.4;
  }
  .automotive_elegance {
    padding: 2rem 0;
  }
  .news_title {
    font-size: 1.2rem;
  }
  .automotive_elegance:after,
  .driving_comfort:after {
    content: "";
    position: absolute;
    inset: 0;    
  }
  .automotive_elegance:after {
    background-color:rgb(0 0 0 / 55%)
  }
  .driving_comfort:after {
    background-color: rgba(255, 255, 255, 0.55);
  }
  .automotive_elegance .container,
  .driving_comfort .container {
    z-index: 1;
  }
}

@media (max-width: 480px) {
  .scroll_bottom {
    width: 1.4rem;
    height: 2.5rem;
  }
  .sc_item_title h5 {
    font-size: 0.8rem;
  }
  .model_info_title {
    font-size: 0.7rem;
  }
  .model_info_legend {
    font-size: 0.7rem;
  }
  .automotive_elegance {
    min-height: 260px;
  }
}

.news_banner {
    padding: 10rem 0 4rem;
}
.single_news_title {
    font-size: 3rem;
    font-weight: 600;
}
.news_header {
    margin-bottom: 2rem;
}
.news_hero > img {
    height: 60vh;
    width: 100%;
    object-fit: cover;
}
.meta_item {
    color: #323232;
    font-size: 1rem;
}
.news_hero {
    margin-bottom: 3rem;
}

@media screen and (max-width: 767px ) {
    .single_news_title {
        font-size: 2rem;
    }
}
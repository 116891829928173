
.navbar.header_main {
    z-index: 999;
    padding: 0;
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    border-radius: 2.6rem;
    transition: all .2s ease-in-out;
    flex-direction: column;
    height: 4.875rem;
    background-color: rgb(0 0 0 / 75%);
    box-shadow: 0px 2px 3rem rgb(35 43 66 / 18%);
    
}
.menuOpened .navbar.header_main:before {
    content: "";
    position: absolute;
    inset: 0;
    background: url(../../../assets/images/menu_bg.webp) top / cover no-repeat ;
    opacity: .1;
    pointer-events: none;
    border-radius: 2.6rem;
}
.navbar.header_main.scrolled {    
    position: fixed;
}
.menuOpened .navbar.header_main { 
    height: calc(100vh - 2rem);
    bottom: 1rem;
    background-color: rgb(0 0 0 / 100%);
    overflow: hidden;
}
/* .navbar.header_main:hover,
.navbar.header_main.scrolled,
.menuOpened .navbar.header_main {
    background-color: #fff;
} */

.site-brand {
    margin-bottom: -1.4rem;
}
.site-brand img {
    width: 100px;
}
/* .logoDark,
.scrolled .logoLight,
.header_main:not(.scrolled):hover .logoLight,
.menuOpened .header_main:not(.scrolled) .logoLight {
    display: none;
}
.scrolled .logoDark,
.header_main:not(.scrolled):hover .logoDark,
.menuOpened .header_main:not(.scrolled) .logoDark {
    display: block;
} */

/* .header_main:not(.scrolled) .menu-toggle:hover {
    color: #fff;
} */

.header_main .nav_bar_menu .nav-item .nav-link {
    color: #e1e1e1;
    font-size: 16px;
    padding: 0;
    transition: color .3s ease-in-out;
}
/* .menuOpened .header_main:not(.scrolled) .nav_bar_menu .nav-item .nav-link,
.header_main:not(.scrolled):hover .nav_bar_menu .nav-item .nav-link,
.header_main.scrolled .nav_bar_menu .nav-item .nav-link {
    color: #4c4c4c;
} */
.header_main .nav_bar_menu .nav-item .nav-link:hover {
    color: var(--primary-color);
}
.header_main .nav_bar_menu .nav-item .nav-link.active {
    color: var(--primary-color);
}
.btn.menu-toggle {
    height: 35px;
    border: 0;
    border-radius: 6px;
    position: relative;
    padding: 0.5rem 0.7rem 0.5rem 2.4rem;
    background-color: transparent;
    box-shadow: none !important;
    color: #fff;
    line-height: 1;
}
/* .header_main:not(.scrolled):hover .menu-toggle,
.scrolled .menu-toggle {
    color: #1C1C1C;
}
.header_main:not(.scrolled):hover .menu-bar,
.scrolled .menu-bar,
.menuOpened .header_main:not(.scrolled) .menu-bar {
    background-color: #1C1C1C;
} */
.menu-bar {
    display: block;
    height: 1px;
    background-color: #bdbdbd;
    width: 20px;
    position: absolute;
    left: 0;
    transition: all .3s cubic-bezier(0.76, 0, 0.01, 0.98);
}
.menu-bar:nth-child(3) {
    top: 22px;
    width: 10px;
}
.menu-bar:nth-child(2) {
    top: 17px;
}
.menu-bar:first-child {
    top: 12px;
}
.menu-toggle.active .menu-bar:first-child {
    top: 16px;
    transform: rotate(45deg);
}
.menu-toggle.active .menu-bar:nth-child(2) {
    left: -40px;
    opacity: 0;
}
.menu-toggle.active .menu-bar:nth-child(3) {
    top: 16px;
    transform: rotate(132deg);
    width: 20px;
}
.drawer_menu {
    position: absolute;
    right: 0;
    left: 0;
    top: 100px;
    overflow-y: auto;
    padding: 3rem 0;
    bottom: 0;
    display: none;
    border-top: 1px solid #323232;
}
.menuOpened .drawer_menu {
    display: block;
}
.drawer_head {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    color: #edeef1;
}
.header_main .nav_menu .nav-item .nav-link {
    display: inline-block;
    padding: .8rem 2rem;
    line-height: 1;
    border-radius: 2rem;
    transition: background, color .3s ease;
    margin: 0.4rem 0;
    font-weight: 500;
    color: #edeef1;
    text-transform: uppercase;
}
.header_main .nav_menu .nav-item .nav-link:hover {
    background-color: #1C1C1C;
}
.header_main .nav_menu .nav-item .nav-link.active {
    background-color: #1C1C1C;
    color: var(--primary-color);
}
.model_item .stack {
    margin-top: 2rem;
}
.model_item .nmi > img {
    width: 100%;
    height: 240px;
    object-fit: contain;
    object-position: center;
}

.nmi.animated {
    animation: scaleX .3s;
}

@keyframes scaleX {
    from {
        transform: translateX(100px) scale(.8);
    }
    to {
        transform: translateX(0) scale(1);
    }
}

.fm_icon {
    flex: 0 0 2.4rem;
    max-width: 2.4rem;
    text-align: center;
}
.fm_label {
    color: #000000;
    font-size: 1rem;
    flex-grow: 1;
    white-space: nowrap;
}
.floating_menu {
    position: fixed;
    right: -8.4rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
}
.floating_menu > a {
    text-decoration: none;
}

.floating_menu_item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.8rem;
    background-color: var(--primary-color);
    width: 12rem;
    transition: transform .3s cubic-bezier(0.65, 0.02, 0.17, 0.99);
}
.floating_menu_item + .floating_menu_item {
    border-top: 1px solid rgb(0 0 0 / 80%);
}
.floating_menu_item:hover {
    transform: translateX(-8rem);
}
.floating_menu a:first-child .floating_menu_item {
    border-top-left-radius: .4rem;
}
.floating_menu a:last-child .floating_menu_item {
    border-bottom-left-radius: .4rem;
}




/*Header End
-------------------------*/
@media screen and (max-width: 991px) { 
    .site-brand img {
        width: 80px;
    }
    .navbar.header_main {
        height: 3.875rem;
    }
}
@media screen and (max-width: 767px) { 
    .drawer_menu .row [class*="col-"]:not(:last-child) {
        margin-bottom: 2rem;
    }        
    .floating_menu_item {
      padding: 0.5rem;
      width: 10.5rem;
    }
    .floating_menu {
      right: -8rem;
    }
    .drawer_menu {
        top: 75px;
    }
    .model_nav.navbar-nav {
        margin-top: 2rem;
    }
}
@media screen and (max-width: 575px) {
    .navbar.header_main {
        padding-left: .50rem;
        padding-right: .50rem;
    } 
    .drawer_menu {
        padding: 1rem 0;
    }
    .drawer_head {
        margin-bottom: 1.5rem;
        font-size: 1.4rem;
    }
    .header_main .nav_menu .nav-item .nav-link {
        padding: 0.5rem 2rem;
        margin: 0.2rem 0;
        font-size: .8rem;
    }
}


